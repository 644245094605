/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:015c9af6-a628-4149-9974-74c86456ce0c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_WHILKBx8a",
    "aws_user_pools_web_client_id": "7e47snadmg8067i7hbdrbdbi9j",
    "oauth": {
        "domain": "blueappcameraauth-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.notacamera.com/",
        "redirectSignOut": "http://localhost:3000/,https://www.notacamera.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://3xngtyctvreejb57lmwg6aibda.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6lfpaajeq5b2bcsx4o7lw2xgum",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "blueappauth-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
