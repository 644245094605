/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDirective = /* GraphQL */ `
  mutation CreateDirective(
    $input: CreateDirectiveInput!
    $condition: ModelDirectiveConditionInput
  ) {
    createDirective(input: $input, condition: $condition) {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDirective = /* GraphQL */ `
  mutation UpdateDirective(
    $input: UpdateDirectiveInput!
    $condition: ModelDirectiveConditionInput
  ) {
    updateDirective(input: $input, condition: $condition) {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDirective = /* GraphQL */ `
  mutation DeleteDirective(
    $input: DeleteDirectiveInput!
    $condition: ModelDirectiveConditionInput
  ) {
    deleteDirective(input: $input, condition: $condition) {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
