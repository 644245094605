/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDirective = /* GraphQL */ `
  subscription OnCreateDirective {
    onCreateDirective {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDirective = /* GraphQL */ `
  subscription OnUpdateDirective {
    onUpdateDirective {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDirective = /* GraphQL */ `
  subscription OnDeleteDirective {
    onDeleteDirective {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      message {
        endpointId
        endpointScopeToken
        correlationToken
        payload
      }
      createdAt
      updatedAt
    }
  }
`;
