// Import useState and useEffect hooks from React
import React, { useEffect, useReducer } from 'react'
// Import the API category from AWS Amplify import { API } from 'aws-amplify'
import './App.css';

import { API } from 'aws-amplify'
import { v4 as uuid } from 'uuid'
import { onCreateDirective } from './graphql/subscriptions'
import { createEvent as CreateEvent } from './graphql/mutations'
import gql from 'graphql-tag'

const initialState = {
  directives: [],
  messages: [],
  loading: true,
  error: false,
  form: { name: '', description: '' }
}

// start();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://webrtc.github.io/adapter/adapter-latest.js";
    script.async = true;
  
    document.body.appendChild(script);

    subscribeToDirective(dispatch)
  }, [])

  return (
    <div className="App">
      <div dangerouslySetInnerHTML={{__html: returnScript()}} />
      {
        state.messages.map((msg, index) => (
          <div key={index}>
            <h2>{msg}</h2>
          </div>))
      }
    </div>
  );
}

function returnScript() {
  return `
  <h1>Not a Camera</h1>
  <video autoplay playsinline></video>
  `
}

const configuration = {
  iceServers: [
    {
      urls: [
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302'
      ],
    },
  ]
};
let myPeerConnection = null;
let localStream = null;



function handleSdp(directive, dispatch) {

  // localVideo = document.getElementById('localVideo');
  console.log('Requesting local stream');
  navigator.mediaDevices.getUserMedia({audio: true, video: { facingMode: "user" }})
  .then(stream => {
    localVideo = document.querySelector('video');

    localStream = stream;
    // localVideo.srcObject = stream;
    console.log('Create PeerConnection with configuration: ', configuration);
    myPeerConnection = new RTCPeerConnection(configuration);
    localStream.getTracks().forEach(track => {
      myPeerConnection.addTrack(track, localStream);
    });

    myPeerConnection.onnegotiationneeded = (event) => {
      console.log("neg");
      dispatch({type: "UPDATE_MESSAGE", message: "READY TO STREAM"})
    }

    myPeerConnection.onicegatheringstatechange = (event) => {
      console.log("ONICECANDIDATE", event);
      console.log("ICE CANDIDATE VALUE", event.target.iceGatheringState);
      if (event.target.iceGatheringState == "complete") {
        console.log("GATHERING COMPLETE!");
        createEvent(directive, myPeerConnection.localDescription);
      }
    }
  
    myPeerConnection.onconnectionstatechange = (event) => {
      dispatch({type: "UPDATE_MESSAGE", message: myPeerConnection.connectionState})
      if (myPeerConnection.connectionState == 'disconnected' ||
      myPeerConnection.connectionState == 'failed') {
        localStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    }
  })
  .then(e => {
    console.log("This should be logged once")
    console.log(directive.message.payload)
    var offer = JSON.parse(directive.message.payload).offer
    console.log(offer)
    console.log(offer.value.replace(
      "a=rtpmap:99 H264/90000\r\n",
      "a=rtpmap:99 H264/90000\r\na=fmtp:99 level-asymmetry-allowed=0;packetization-mode=0;profile-level-id=42e01f\r\n"
      ))
    // console.log(offer.value.replaceAll("video 1", "video 9").replaceAll("audio 1", "audio 9"));
    var sdp = {
      type: "offer",
      sdp: offer.value.replace(
        "a=rtpmap:99 H264/90000\r\n",
        "a=rtpmap:99 H264/90000\r\na=fmtp:99 packetization-mode=1;\r\n"
        )
    }  
    return myPeerConnection.setRemoteDescription(sdp);
  })
  .then(function() {
    return myPeerConnection.createAnswer();
  })
  .then(function(answer) {
    return myPeerConnection.setLocalDescription(answer);
  })
  .catch(function(e) {console.log(e)});
}

// Video element where stream will be placed.
var localVideo = document.querySelector('video');


function start(dispatch) {
  console.log('Requesting local stream');
  navigator.mediaDevices.getUserMedia({audio: true, video: { facingMode: "user" }})
  .then(stream => {
    localStream = stream;
    console.log('Create PeerConnection with configuration: ', configuration);
    myPeerConnection = new RTCPeerConnection(configuration);
    localStream.getTracks().forEach(track => {
      myPeerConnection.addTrack(track, localStream);
    });

  myPeerConnection.onnegotiationneeded = (event) => {
    console.log("neg");
    dispatch({type: "UPDATE_MESSAGE", message: "READY TO STREAM"})
  }
  })
  .catch(e => {alert(e)})
}

function delay(t) {
  return new Promise(function(resolve) {
      setTimeout(resolve, t);
  });
}

function subscribeToDirective(dispatch) {
  const subscription = API.graphql({
    query: onCreateDirective
  })
    .subscribe({
      next: directiveData => {
        const directive = directiveData.value.data.onCreateDirective
        // dispatch({ type: 'ADD_DIRECTIVE', directive })
        handleSdp(directive, dispatch)
      },
      error: error => {
        console.log('Subscription error:', error)
        setTimeout(function(){ subscribeToDirective(dispatch); }, 1000);
      }
    })
  return () => subscription.unsubscribe()
}

async function createEvent(directive, localSdp) {
  try {
    console.log(localSdp)
    console.log(directive)
    console.log("SENDING UP EVENT");
    await API.graphql({
      query: CreateEvent, 
      variables: { 
        input: {
          message: {
            endpointId: directive.message.endpointId,
            endpointScopeToken: directive.message.endpointScopeToken,
            correlationToken: directive.message.correlationToken,
            payload: JSON.stringify({
              answer: {
                format: "SDP",
                value: localSdp.sdp
              }
            })
          }
        } 
      }
    })
    console.log('successfully created note!')
  } catch (err) {
    console.log("error: ", err)
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_DIRECTIVE':
      return { ...state, directives: [action.directive, ...state.directives] }
    case 'UPDATE_MESSAGE':
        return { ...state, messages: [action.message, ...state.messages] }
    case 'ERROR':
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default App