/* src/Public.js */
import React from 'react'
import Container from './Container'

function Public() {
    return (
        <Container>
            <h1>Public route</h1>
        </Container>
    )
}

export default Public